import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { ContactStyled } from "./contactStyle"

import icon1 from "@/images/info.png"
import icon2 from "@/images/building.png"
import { EmailButton, PhoneButton } from "../shared/button/button"
import ContactForm from "../shared/contactForm/contactForm"
import { injectIntl } from "gatsby-plugin-intl"
// import Status from "../shared/status/status"
import ContactItem from "@/components/contact/contactItem"

const Contact = ({ intl }) => {
  // const state = typeof window !== "undefined" && localStorage.getItem("id")
  const ref =
    typeof window !== "undefined" && sessionStorage.getItem("refferer")

  const gtag_report_mailto_conversion = () => {
    if (window.gtag_report_mailto_conversion) {
      window.gtag_report_mailto_conversion()
    }
  }
  const gtag_phone_conversion = () => {
    if (window.gtag_phonecall_conversion) {
      window.gtag_phonecall_conversion()
    }
  }
  return (
    <ContactStyled>
      <Container>
        <Row>
          <Col xs={12} lg={6} className={"contact-wrapper"}>
            <div className="heading">
              <h1>{intl.formatMessage({ id: "contact.title" })}</h1>
              <p className={"subtitle-head"}>
                {intl.formatMessage({ id: "contact.content.bold" })}
              </p>
              <p>{intl.formatMessage({ id: "contact.content" })}</p>
            </div>
            <Row>
              <div className={"contactV2--wrapper"}>
                <Col className={"p-0"}>
                  <Row className={"mt-2"}>
                    <Col sm="12" md="6" className="contact--sales_buttons mb-1">
                      <PhoneButton narrower={true} />
                    </Col>
                    <Col sm="12" md="6" className="contact--sales_buttons">
                      <EmailButton narrower={true} showStatus={true} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className={"p-0 mt-1"}>
                      <div className="office--info">
                        <div
                          className={
                            "contactV2--title d-flex align-items-center"
                          }
                        >
                          <div className="icon">
                            <img src={icon1} alt="" />
                          </div>
                          <span>
                            {intl.formatMessage({ id: "office.title" })}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12" md="6" className={"mob-p0 mb-3"}>
                      <div className="subtitle">Willbud Rumia</div>
                      <p>ul. Błoń Janowskich 23</p>
                      <p>84-230 Rumia</p>
                      <p>Pn. - Pt. 08:00 - 19:00</p>
                      <p className={"pt-9"} style={{ fontSize: "18px" }}>
                        Sb. - 08:00 - 15:00
                      </p>
                      {/*<Status from={1000} to={1800} />*/}
                    </Col>
                    <Col sm="12" md="6" className={"mob-p0 mb-4"}>
                      <div className="subtitle">Willbud Gdynia</div>
                      <p>
                        {intl.formatMessage({
                          id: "headquater.adress.street",
                        })}
                      </p>
                      <p>
                        {intl.formatMessage({
                          id: "headquater.adress.post",
                        })}
                      </p>
                      <p>
                        {intl.formatMessage({
                          id: "sales.headquater.adress.hours",
                        })}
                      </p>
                      <p className={"pt-9"} style={{ fontSize: "18px" }}>
                        {intl.formatMessage({
                          id: "office.adress.hours.weekend",
                        })}
                      </p>
                      {/*<Status from={800} to={1600} />{" "}*/}
                      {/*className={'l-mt-51'}*/}
                    </Col>
                    <Col sm="12" md="6" className={"mob-p0 mb-3"}>
                      <div className="subtitle">Willbud Puck</div>
                      <p>ul. Wejherowska 43</p>
                      <p>84-100 Puck</p>
                      <p>Pn. - Pt. 10:00 - 18:00</p>
                      <p className={"pt-9"} style={{ fontSize: "18px" }}>
                        {intl.formatMessage({
                          id: "office.adress.hours.weekend",
                        })}
                      </p>
                      {/*<Status from={1000} to={1800} />*/}
                    </Col>
                  </Row>

                  <Row>
                    <Col className={"p-0 mb-3"}>
                      <div className="office--info">
                        <div
                          className={
                            "contactV2--title d-flex align-items-center"
                          }
                        >
                          <div className="icon">
                            <img src={icon1} alt="" />
                          </div>
                          <span>
                            Dane kontaktowe do poszczególnych działów w firmie
                            WILLBUD
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* <!-- contact accordeon --> */}

                  {[
                    {
                      title: "contact.email.sales",
                      // email: "sprzedaz@willbud.pl",
                      height: 700,
                      text: [
                        `
                          <u>Biuro Sprzedaży Rumia</u><br/>
                          Infolinia: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 1</span><br/>
                          <a href="mailto:sprzedaz@willbud.pl" onclick="gtag_report_mailto_conversion()">sprzedaz@willbud.pl</a><br/>
                          poniedziałek – piątek: 08:00 – 19:00<br/>
                          sobota: 08:00 - 15:00
                        `,
                        `
                        <u>Biuro Sprzedaży Gdynia</u><br/>
                          Infolinia: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 2</span><br/>
                          <a href="mailto:sprzedaz@willbud.pl" onclick="gtag_report_mailto_conversion()">sprzedaz@willbud.pl</a><br/>
                          poniedziałek – piątek: 08:00 – 16:00<br/>
                          sobota: po wcześniejszym umówieniu spotkania
                        `,
                        `
                        <u>Biuro Sprzedaży Puck</u><br/>
                          Infolinia: <a href='tel:572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 3</span><br/>
                          <a href="mailto:sprzedaz@willbud.pl" onclick="gtag_report_mailto_conversion()">sprzedaz@willbud.pl</a><br/>
                          poniedziałek – piątek: 10:00 – 18:00<br/>
                          sobota: po wcześniejszym umówieniu spotkania

                        `,
                        `
                        <u>Aktualnie sprzedajemy lokale w inwestycjach:</u>
                        <br/>

                          1. <a href="/${intl.formatMessage({
                            id: "language.indicatior",
                          })}/oferta/osiedle-pogodne-w-pucku">Osiedle Pogodne w Pucku</a> – lokale mieszkalne i apartamenty inwestycyjne.
                          <br/>
                          2. <a href="/${intl.formatMessage({
                            id: "language.indicatior",
                          })}/oferta/osiedle-janowo-park-3">Osiedle Janowo Park III w Rumi</a> – lokale mieszkalne i usługowe.
                          <br/>
                          3. <a href="/${intl.formatMessage({
                            id: "language.indicatior",
                          })}/oferta/osiedle-gdynia-wiczlino-2">Osiedle Gdynia Wiczlino II w Gdyni</a> – domy jednorodzinne dwulokalowe.

                        `,
                      ],
                    },
                    {
                      title: "contact.email.arrangement",
                      //email: "aranzacje@willbud.pl",
                      text: [
                        `
                          Infolinia: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 4</span><br/>
                          <a href="mailto:aranzacje@willbud.pl" onclick="gtag_report_mailto_conversion()">aranzacje@willbud.pl</a><br/>
                          poniedziałek – piątek: 8:00 – 16:00<br/>
                        `,
                        "Dostosuj własny lokal jeszcze na etapie budowy.<br>Skontaktuj się z nami w celu dokonania niezbędnych zmian.",
                      ],
                    },
                    {
                      title: "contact.email.finishing",
                      //email: "design@willbud.pl",
                      text: [
                        `
                          Infolinia: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 4</span><br/>
                          Adres email: <a href="mailto:design@willbud.pl" onclick="gtag_report_mailto_conversion()">design@willbud.pl</a><br/>
                          poniedziałek – piątek: 8:00 – 16:00<br/>
                        `,
                        `Przygotowaliśmy dla Państwa pakiety wykończeniowe w atrakcyjnych cenach. Odebierz lokal wykończony pod klucz. Zapraszamy na spotkanie z naszym Architektem.`,
                      ],
                    },
                    {
                      title: "contact.email.credits",
                      // email: "klient@willbud.pl",
                      text: [
                        `
                          Infolinia: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 5</span><br/>
                          Adres email: <a href="mailto:klient@willbud.pl" onclick="gtag_report_mailto_conversion()">klient@willbud.pl</a><br/>
                          poniedziałek – piątek: 8:00 – 16:00<br/>
                        `,
                        `Skorzystaj z naszych usług kredytowych na najlepszych warunkach.`,
                      ],
                    },
                  ].map(contact => (
                    <ContactItem
                      height={contact.height || null}
                      label={intl.formatMessage({ id: contact.title })}
                    >
                      {contact.email && (
                        <p>
                          {intl.formatMessage({ id: "contact.email.to" })}{" "}
                          <a
                            href={`mailto:${contact.email}`}
                            onClick={gtag_report_mailto_conversion}
                          >
                            {contact.email}
                          </a>
                        </p>
                      )}
                      {contact.text &&
                        contact.text.map(p => (
                          <p dangerouslySetInnerHTML={{ __html: p }} />
                        ))}
                    </ContactItem>
                  ))}

                  <ContactItem
                    label={intl.formatMessage({
                      id: "afterSalesService.title",
                    })}
                    height={600}
                  >
                    <p className={"pt-2"}>
                      Infolinia:{" "}
                      <a href="tel:48572361213" onClick={gtag_phone_conversion}>
                        572 36 12 13
                      </a>
                      , <span>nr wewnętrzny 5</span>
                      <br />
                      Adres email:{" "}
                      <a
                        href="mailto:klient@willbud.pl"
                        onClick={gtag_report_mailto_conversion}
                      >
                        klient@willbud.pl
                      </a>
                      <br />
                      poniedziałek – piątek: 8:00 – 16:00
                      <br />
                    </p>
                    <p>
                      Dział obsługi posprzedażowej odpowie na każde Państwa
                      pytanie od chwili podpisania umowy deweloperskiej do
                      odbioru lokalu, a w tym wszelkie pytania dotyczące
                      rozliczeń związanych z zakupionym lokalem.
                    </p>
                  </ContactItem>
                  <ContactItem
                    label={intl.formatMessage({
                      id: "warrantyAndService.title",
                    })}
                  >
                    <p className={"pt-2"}>
                      Infolinia:{" "}
                      <a href="tel:48572361213" onClick={gtag_phone_conversion}>
                        572 36 12 13
                      </a>
                      , <span>nr wewnętrzny 6</span>
                      <br />
                      Adres email:{" "}
                      <a
                        href="mailto:serwis@willbud.pl"
                        onClick={gtag_report_mailto_conversion}
                      >
                        serwis@willbud.pl
                      </a>
                      <br />
                      poniedziałek – piątek: 8:00 – 16:00
                      <br />
                    </p>
                    <p>
                      Nasz serwis gwarantuje Państwu opiekę w okresie gwarancji
                      oraz przyjmie zgłoszenia związane z usterkami lub
                      reklamacjami.
                    </p>
                  </ContactItem>

                  <ContactItem label="Marketing">
                    <p className={"pt-2"}>
                      Nr telefonu:{" "}
                      <a href="tel:48572361213" onClick={gtag_phone_conversion}>
                        572 36 12 13
                      </a>
                      , <span>nr wewnętrzny 8</span>
                      <br />
                      Adres email:{" "}
                      <a
                        href="mailto:marketing@willbud.pl"
                        onClick={gtag_report_mailto_conversion}
                      >
                        marketing@willbud.pl
                      </a>
                      <br />
                      poniedziałek – piątek: 8:00 – 16:00
                      <br />
                    </p>
                    <p>
                      Zapraszamy do przesyłania ofert dotyczących współpracy w
                      zakresie marketingu.
                    </p>
                  </ContactItem>

                  <ContactItem
                    label={intl.formatMessage({ id: "landPurchase.title" })}
                  >
                    <p className={"pt-2"}>
                      Nr telefonu:{" "}
                      <a href="tel:48572361213" onClick={gtag_phone_conversion}>
                        572 36 12 13
                      </a>
                      , <span>nr wewnętrzny 8</span>
                      <br />
                      Adres email:{" "}
                      <a
                        href="mailto:grunty@willbud.pl"
                        onClick={gtag_report_mailto_conversion}
                      >
                        grunty@willbud.pl
                      </a>
                      <br />
                      poniedziałek – piątek: 8:00 – 16:00
                      <br />
                    </p>
                    <p>
                      <u>
                        Stale poszukujemy atrakcyjnych nieruchomości pod
                        zabudowę wielorodzinną oraz usługową na terenie
                        Trójmiasta i okolic. Zapraszamy do przesyłania ofert.
                      </u>
                    </p>
                  </ContactItem>

                  {[
                    {
                      title: "Willbud Budownictwo",
                      // email: "zaopatrzenie@willbud.pl",
                      text: [
                        `
                        Nr telefonu: <a href='tel:48572361213' onclick="gtag_report_mailto_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 7</span><br/>
                        Adres email: <a href="mailto:zaopatrzenie@willbud.pl" onclick="gtag_report_mailto_conversion()">zaopatrzenie@willbud.pl</a><br/>
                        poniedziałek – piątek: 8:00 – 16:00<br/>
                      `,
                        "Zapraszamy do współpracy z Podwykonawców, Dystrybutorów, Hurtownie, Producentów.",
                      ],
                    },
                    {
                      title: "Sekretariat",
                      // email: "sekretariat@willbud.pl",
                      text: [
                        `
                        Nr telefonu: <a href='tel:48572361213' onclick="gtag_phonecall_conversion()">572 36 12 13</a>, <span>nr wewnętrzny 8</span><br/>
                        Adres email: <a href="mailto:sekretariat@willbud.pl" onclick="gtag_report_mailto_conversion()">sekretariat@willbud.pl</a><br/>
                        poniedziałek – piątek: 8:00 – 16:00<br/>
                      `,
                      ],
                    },
                  ].map(contact => (
                    <ContactItem label={contact.title}>
                      {contact.text &&
                        contact.text.map(p => (
                          <p dangerouslySetInnerHTML={{ __html: p }} />
                        ))}
                    </ContactItem>
                  ))}

                  {/*<div className="mb-4">
                    <ContactItem
                      label={intl.formatMessage({ id: "headquater.title" })}
                    >
                      <div className="subtitle">Willbud SP. Z O.O. SP. K</div>
                      <p>
                        {intl.formatMessage({id: "headquater.adress.street"})}
                        <br/>
                        {intl.formatMessage({ id: "headquater.adress.post" })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: "company.adress.nip" })}<br/>
                        {intl.formatMessage({ id: "company.adress.krs" })}
                      </p>
                    </ContactItem>
                  </div>*/}
                  <Row>
                    <Col className={"p-0"}>
                      <div className="office--info">
                        <div
                          className={
                            "contactV2--title d-flex align-items-center"
                          }
                        >
                          <div className="icon">
                            <img src={icon2} alt="" />
                          </div>
                          <span>
                            {intl.formatMessage({ id: "company.title" })}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" md="6" className={"mob-p0 mb-5"}>
                      <div className="subtitle">
                        WILLBUD M. WILLA S.K.A.
                        {/*{intl.formatMessage({ id: "company.name" })}*/}
                      </div>
                      <p>
                        {intl.formatMessage({
                          id: "headquater.adress.street",
                        })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: "headquater.adress.post" })}
                      </p>
                      <p>{intl.formatMessage({ id: "company.adress.nip" })}</p>
                      <p>{intl.formatMessage({ id: "company.adress.krs" })}</p>
                      <p>
                        {intl.formatMessage({ id: "company.adress.regon" })}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
          </Col>
          <Col
            lg={6}
            className={
              "d-none d-lg-block position-static form-container-parent"
            }
          >
            <div className="bg-form"></div>
            <ContactForm refferer={ref} />
          </Col>
        </Row>
      </Container>
      <div className="bg-gray d-block d-lg-none">
        <Container>
          <ContactForm refferer={ref} />
        </Container>
      </div>
      <div className="buttons-call d-block d-lg-none">
        <Container>
          <PhoneButton noIcon={true} />
          <EmailButton noIcon={true} />
        </Container>
      </div>
    </ContactStyled>
  )
}

export default injectIntl(Contact)
